<template>
   <!-- <div id="clouds">
            <div class="cloud x1"></div>
            <div class="cloud x1_5"></div>
            <div class="cloud x2"></div>
            <div class="cloud x3"></div>
            <div class="cloud x4"></div>
            <div class="cloud x5"></div>
        </div>
        <div class='c'>
            <div class='_404'>404</div>
            <hr>
            <div class='_1'>THE PAGE</div>
            <div class='_2'>WAS NOT FOUND</div>
            <router-link to="/" class='btn'>الرجوع الي الصفحة الرئيسية</router-link>
        </div> -->
        <section class="page_404">
	<div class="container">
		<div class="row">	
		<div class="col-sm-12 ">
		<div class="col-sm-10 col-sm-offset-1  text-center">
		<div class="four_zero_four_bg">
			<h1 class="text-center ">404</h1>
		
		
		</div>
		
		<div class="contant_box_404">
		<h3 class="h2">
		يبدو أنك تائه
		</h3>
		
		<p>الصفحة التي تبحث عنها غير متوفرة!</p>
		
		<router-link to="/" class="link_404">الرجوع الي الصفحة الرئيسية</router-link>
	</div>
		</div>
		</div>
		</div>
	</div>
</section>
</template>

<script>
export default {
   name: 'AppNotFounds'
}
</script>

<style scoped>



/*======================
    404 page
=======================*/


.page_404{ 
    padding: 40px 0;
background: #fff;
font-family: 'Arvo', serif;
height: 100vh;
margin-top: 80px;
}

.page_404  img{ width:100%;}

.four_zero_four_bg{
 
 background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    background-position: center;
 }
 
 
 .four_zero_four_bg h1{
 font-size:80px;
 }
 
  .four_zero_four_bg h3{
			 font-size:80px;
			 }
			 
			 .link_404{			 
	color: #fff!important;
    padding: 10px 20px;
    background: var(--primary-color);
    margin: 20px 0;
    display: inline-block;}
	.contant_box_404{ margin-top:-50px;}

</style>